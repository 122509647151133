<template>
  <v-container id="homeWorth" tag="section" class="homeWorthContainer">
    <section id="home-worth">
      <v-container class="text-center">
        <h2 class="display-1 font-weight-bold mb-3">
          How Much Is My Home Worth?
        </h2>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          <p>
            To find out what your home may be worth in today's market, please
            fill out the short form below to get a FREE market evaluation of
            your property!
          </p>
        </v-responsive>
        <v-form v-if="!didSubmit" v-model="valid">
          <v-container>
            <v-row justify="center">
              <v-col :cols="colSize" md="2">
                <v-text-field
                  v-model="firstName"
                  :rules="nameRules"
                  :counter="20"
                  label="First name"
                  required
                  solo
                />
              </v-col>

              <v-col :cols="colSize" md="2">
                <v-text-field
                  v-model="lastName"
                  :rules="nameRules"
                  :counter="20"
                  label="Last name"
                  required
                  solo
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col :cols="colSize" md="2">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  solo
                />
              </v-col>

              <v-col :cols="colSize" md="2">
                <v-text-field
                  v-model="phoneNumber"
                  :rules="phoneRules"
                  label="Phone #"
                  required
                  solo
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="address"
                  :rules="addressRules"
                  label="Address"
                  required
                  solo
                />
              </v-col>

              <v-col :cols="colSize" md="1">
                <v-select
                  v-model="bedrooms"
                  :items="numbers"
                  label="Bedrooms"
                  solo
                />
              </v-col>

              <v-col :cols="colSize" md="1">
                <v-select
                  v-model="washrooms"
                  :items="numbers"
                  label="Washrooms"
                  solo
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="4">
                <v-textarea
                  solo
                  name="input-7-4"
                  label="Please enter any other details you would like to share."
                  auto-grow
                />
              </v-col>
            </v-row>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="submitForm"
            >
              Submit
            </v-btn>
          </v-container>
        </v-form>

        <v-col v-if="didSubmit">
          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            <base-subheading class="mb-3"> Thank you </base-subheading>

            <p>
              You have successfully submitted your home evaluation request. I
              will contact you within the next few days to help you understand
              what your home may be worth.
            </p>
          </v-responsive>
        </v-col>
      </v-container>
    </section>
  </v-container>
</template>
<script>
import firebase from "firebase/app";
import "firebase/database";

export default {
  data: () => ({
    database: firebase.database(),
    didSubmit: false,
    valid: false,
    firstName: "",
    lastName: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 20 || "Name must be less than 20 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    phoneNumber: "",
    phoneRules: [
      (v) => !!v || "Phone # is required",
      (v) =>
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) ||
        "Phone # must be valid",
    ],
    address: "",
    addressRules: [(v) => !!v || "Address is required"],
    numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    bedrooms: undefined,
    washrooms: undefined,
    extraDetails: "",

    windowWidth: window.innerWidth,
  }),

  computed: {
    colSize() {
      return this.windowWidth <= 600 ? 6 : 12;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    submitForm() {
      this.database
        .ref("home-worth/")
        .push({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          address: this.address,
          bedrooms: this.bedrooms,
          washrooms: this.washrooms,
          extraDetails: this.extraDetails,
        })
        .then(() => {
          this.didSubmit = true;
        });
    },

    handleResize() {
      this.windowWidth = window.innerWidth; // Update the reactive property on resize
    },
  },
};
</script>
<style lang="scss">
v-row {
  padding: 5px;
}

.homeWorthContainer {
  background-color: #ffffff;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 10px;
  }
}
</style>
